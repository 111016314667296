import "./App.css";
import About from "./Files/About";
// import Blog from "./Files/Blog";
import Contact from "./Files/Contact";
import Head from "./Files/Head";
// import Linreg from './Files/Linreg';
import Navbar from "./Files/Navbar";
import Projects from "./Files/Projects";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import NavbarBlog from "./Files/NavbarBlog";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                {/* <Blog /> */}
                <Navbar />
                <Head />
                <Projects />
                <About />
                <Contact />
                {/* <Linreg /> */}
              </div>
            }
          ></Route>
          {/* <Route
            path="/blogs"
            element={
              <div className="App">
                <Blog />
                <NavbarBlog />
                <Contact />
                <Linreg /> 
              </div>
            }
          ></Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
