import React from 'react'
import './skills.css'

function Skills() {
  return (
    <div className='skillMain'>
        <div className="skills_sub_heading">Programming</div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg python"></div>
                  <div className='skillName'>Python</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg sql"></div>
                  <div className='skillName'>SQL</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg java"></div>
                  <div className='skillName'>Java</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg rlang"></div>
                  <div className='skillName'>R</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg cpp"></div>
                  <div className='skillName'>C/C++</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg js"></div>
                  <div className='skillName'>Java Script</div>
            </div>
        </div>
        <div className="skills_sub_heading">Development</div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg html"></div>
                  <div className='skillName'>HTML</div>
            </div>
        </div> 
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg css"></div>
                  <div className='skillName'>CSS/Bootstrap</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg git"></div>
                  <div className='skillName'>Git</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg github"></div>
                  <div className='skillName'>GitHub</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg heroku"></div>
                  <div className='skillName'>Heroku</div>
            </div>
        </div>
        <div className="skills_sub_heading">Libraries and Framework</div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg flask"></div>
                  <div className='skillName flaskName'>Flask</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg react"></div>
                  <div className='skillName'>ReactJS</div>
            </div>
        </div> 
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg tf"></div>
                  <div className='skillName'>TensorFlow</div>
            </div>
        </div> 
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg keras"></div>
                  <div className='skillName'>Keras</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg numpy"></div>
                  <div className='skillName'>Numpy</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg pandas"></div>
                  <div className='skillName'>Pandas</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg sklearn"></div>
                  <div className='skillName'>Scikit-Learn</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg opencv"></div>
                  <div className='skillName'>OpenCV</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg optuna"></div>
                  <div className='skillName'>Optuna</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg restapi"></div>
                  <div className='skillName'>RestAPI</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg ros"></div>
                  <div className='skillName'>ROS</div>
            </div>
        </div>
        <div className="skills_sub_heading">Tools</div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg powerbi2"></div>
                  <div className='skillName'>PowerBI</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg tableau"></div>
                  <div className='skillName'>Tableau</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg spreadsheet"></div>
                  <div className='skillName'>Spreadsheet</div>
            </div>
        </div>
        <div className="btn skillx">
            <div className="container-fluid skillBox">
                  <div className="mb-0 skillImg mysql"></div>
                  <div className='skillName'>MySQL</div>
            </div>
        </div>
    </div>
  )
}

export default Skills