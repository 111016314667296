import React from 'react'
import './navbar.css'

function Navbar() {

  window.onscroll = function() {scrollFunction()};
  let mybutton = document.getElementById("btnBackToTop");

  function scrollFunction() {
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      document.getElementById("navClass").style.display = "block";
      mybutton.style.display = "block";
    } else {
      document.getElementById("navClass").style.display = "none";
      mybutton.style.display = "none";
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
      <div className="navClass fixed-bottom" id='navClass'>
          <nav className="navbar navx">
              <div className="container-fluid navsubx">
                  <div className="linksNav fa-xl">
                    <a href="https://www.linkedin.com/in/aryan-gadhiya-918b1221b" rel='noreferrer' target="_blank"><i className="fa-brands fa-linkedin"></i></a>
                    {/* <i className="fa-brands fa-twitter"></i> */}
                    <a href="https://github.com/aryan16x" rel='noreferrer' target="_blank"><i className="fa-brands fa-github"></i></a>
                    {/* <i className="fa-brands fa-instagram"></i> */}
                  </div>
                  <span className="navbar-brand mb-0 h1 namex" id='namex'>ARYAN GADHIYA</span>
                  <center><i className="fa-solid fa-house fa-xl"  id="btnBackToTop" onClick={backToTop}/></center>
              </div>
          </nav>
      </div>
  )
}

export default Navbar