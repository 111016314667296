import React from 'react'
import './head.css'
import { Link } from 'react-scroll'
import { Link as Linkx } from 'react-router-dom'

export default function Head() {

  return (
    <div className='head'>
        <div className="headImg">
          <div className='imgx'></div>
        </div>
        <div className="string">
            <p className='str1'>hi, i'm <span>Aryan</span></p>
            <p className='str2'>student at vit, vellore</p>
        </div>
        <div className="btn-group btnGroup" role="group" aria-label="Basic example">
          <Link to="aboutx"><button type="button" className="btn butx butx1">About me</button></Link>
          <Link to="projectsx"><button type="button" className="btn butx butx2">Projects</button></Link>
          <a href='https://drive.google.com/file/d/1RDLqY5PypqRlILqZbMP0wAfZPmq_UPUh/view?usp=sharing' target='_blank'><button type="button" className="btn butx butx3">Resume</button></a>
          {/* <Linkx to="/blogs"><button type="button" className="btn butx butx4">Blog</button></Linkx> */}
        </div>
    </div>
  )
}
