import React, { useState } from "react";
import "./Contact.css";
// import axios from 'axios';

function Contact() {

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  // const [result, setResult] = useState('')

  // const submitForm = (e) => {
  //   console.log("clicked");
  //   e.preventDefault();
  //   axios
  //     .post("http://127.0.0.1:5000/contact", {
  //       "email": email,
  //       "message": message,
  //     })
  //     .then((res) => {
  //       setResult(res.data.status);
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //       setResult("Try Again");
  //     })
  // }

  // const alertPlaceholder = document.getElementById('liveAlertPlaceholder')

  // const alert = (message, type) => {
  //   const wrapper = document.createElement('div')
  //   wrapper.innerHTML = [
  //     `<div className="alert alert-${type} alert-dismissible" role="alert">`,
  //     `   <div>${message}</div>`,
  //     '   <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
  //     '</div>'
  //   ].join('')

  //   alertPlaceholder.append(wrapper)
  // }

  return (
    <>
      <footer id="contactx">
        <div className="main-content">
          <div className="center box">
            <div className="content">
              <div className="place">
                <span className="fas footerFirst fa-map-marker-alt"></span>
                <span className="text">Surat, Gujarat</span>
              </div>
              {/* <div className="phone">
                <span className="fas fa-phone-alt"></span>
                <span className="text">+089-765432100</span>
              </div> */}
              <div className="email">
                <span className="fas fa-envelope"></span>
                <span className="text">ary16.work@gmail.com</span>
              </div>
            </div>
          </div>

          <div className="right box">
            <div className="content">
              <form action="#">
                <div className="email">
                  <div className="text formTxt">Email</div>
                  <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required disabled/>
                </div>
                <div className="msg">
                  <div className="text formTxt">Message</div>
                  <textarea rows="2" cols="25" value={message} onChange={(e)=>{setMessage(e.target.value)}} required disabled></textarea>
                </div>
                {/* <div className="btnx" onClick={submitForm}> */}
                <div className="btnx">
                  <button type="submit" id="submitFormx" disabled>Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bottom">
          <center>
            <span className="credit">
              Created By <a href="https://github.com/aryan16x">aryan16x</a> |{" "}
            </span>
            <span className="far fa-copyright"></span>
            <span className="credit">
              {" "}
              2023
            </span>
          </center>
        </div>
      </footer>
    </>
  );
}

export default Contact;
