import React from 'react'
import './about.css'
import Skills from './Skills'

function About() {
  return (
    <div className='aboutx' id='aboutx'>

        <div className="introx">
            <p>about me</p>
        </div>

        <div className="experience">
            <div className="headabx headExp">experience</div>
            <div className="container bodyExp">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card cardx">
                            <div className="card-body">
                                <h5 className="card-title">Web Developer</h5>
                                <p className="card-text">Hareestar Infrastructure<br/>2022 Nov - 2023 Jan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="education">
            <div className="headabx headEdu">education</div>
            <div className="container bodyEdu">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card cardx">
                        <div className="card-body">
                            <h5 className="card-title">CSE with Specialization in IOT</h5>
                            <p className="card-text">Vellore Institute of Technology, Vellore<br/>2021 - 2025</p>
                            <p className="card-text2"><ul><li>Expected Graduation : July, 2025</li></ul></p>
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card cardx">
                        <div className="card-body">
                            <h5 className="card-title">Higher Secondary Education</h5>
                            <p className="card-text">Shree Swaminarayan Gurukul Vidhyalaya, Surat<br/>2019 - 2021</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="skills">
            <div className="headabx headSkills">technical skills</div>
            <div className="container bodySkills">
                <Skills />
            </div>
        </div>

        <div className="language">
            <div className="headabx headLan">languages</div>
            <div className="container bodyLan">
                <ul>
                    <li>English</li>
                    <li>Hindi</li>
                    <li>Gujarati</li>
                </ul>
            </div>
        </div>

    </div>
  )
}

export default About