import React from 'react'
// import { useState } from 'react'
import "./projects.css"
import Blueberry from './Blueberry'
// import axios from 'axios';

function Projects() {

//   const [fixedAcidity, setFA] = useState('');
//   const [volatileAcidity, setVA] = useState('');
//   const [citricAcid, setCA] = useState('');
//   const [residualSugar, setRS] = useState('');
//   const [chlorides, setCH] = useState('');
//   const [freeSulpher, setFS] = useState('');
//   const [totalSulpher, setTS] = useState('');
//   const [density, setDT] = useState('');
//   const [pH, setPH] = useState('');
//   const [sulphates, setSP] = useState('');
//   const [alcohol, setAH] = useState('');

//   const [date, setDate] = useState('');
//   const [month, setMonth] = useState('');
//   const [time, setTime] = useState('');
//   const [temp, setTemp] = useState('');
//   const [humidity, setHumidity] = useState('');
//   const [windspeed, setWS] = useState('');


//   const predictionButton = (e) => {
//     e.preventDefault();
//     axios
//         .post("http://127.0.0.1:5000/wineModel", {
//             "fixed_acidity": fixedAcidity,
//             "volatile_acidity": volatileAcidity,
//             "citric_acid": citricAcid,
//             "residual_sugar": residualSugar,
//             "chlorides": chlorides,
//             "free_sul_dio": freeSulpher,
//             "total_sul_dio": totalSulpher,
//             "density": density,
//             "pH": pH,
//             "sulphates": sulphates,
//             "alcohol": alcohol,
//         })
//         .then((res) => {
//             document.getElementById("returnValWine").placeholder = res.data;
//         })
//         .catch((err) => {
//             console.error(err);
//         })
//   }

//   const bikePredictionButton = (e) => {
//     e.preventDefault();
//     axios
//         .post("http://127.0.0.1:5000/bikeModel", {
//             "date":date,
//             "month": month,
//             "time": time,
//             "temp": temp,
//             "humidity": humidity,
//             "windspeed": windspeed,
//         })
//         .then((res) => {
//             document.getElementById("returnValBike").placeholder = res.data;
//         })
//         .catch((err) => {
//             console.error(err);
//         })
//   }
  
  

  return (
    <div className="projects" id='projectsx'>

        <div className="introx2">
            <p>projects</p>
        </div>

        <div className="container">
            <div className="row">
                
            <div className="col-sm-6 colxx">
                     <div className="card cardxproj signlang">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">A Media-pipe integrated deep learning model for ISL (Alphabet) recognition</div>
                            <div className="prosubtitle p1subtitle">This model will have a profound positive impact on over 70 million deaf individuals worldwide, greatly benefiting them in numerous ways.</div>
                            <span className="badge text-bg-light">Data science</span>
                            <span className="badge text-bg-light">TensorFlow</span>
                            <span className="badge text-bg-light">Keras</span>
                            <span className="badge text-bg-light">Optuna</span>
                            <span className="badge text-bg-light">Mediapipe</span>
                            <span className="badge text-bg-light">OpenCV</span>
                            <a href="https://github.com/aryan16x/SIGN-LANGUAGE-DETECTION" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
                <Blueberry />
                {/* <div className="col-sm-6 colxx">
                    <div className="card cardxproj lregmodel">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">Bike Sharing Demand</div>
                            <div className="prosubtitle p1subtitle">Forecast use of a city bikeshare system</div>
                            <span className="badge text-bg-light">Machine Learning</span>
                            <span className="badge text-bg-light">Regression Model</span>
                            <center><button className="btn btn-primary inButton" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">Try out this project...</button></center>

                            <div className="offcanvas offcanvas-start text-bg-dark" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                                <div className="canvasHeader offcanvas-header">
                                    <h5 className="offcanvas-title canvasTitle" id="offcanvasWithBothOptionsLabel">Project Title</h5>
                                    <button type="button" className="btn-close btn-close-white outButton" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body canvasBody">
                                    <form className='formClass'>
                                        <div className="row mb-3">
                                            <label htmlFor="date" className="col-sm-5 col-form-label">Date</label>
                                            <div className="col-sm-5 mb-1">
                                            <input type="text" value={date} onChange={(e)=>{setDate(e.target.value)}} className="form-control" id="bedroom" />
                                            </div>
                                            <label htmlFor="month" className="col-sm-5 col-form-label">Month</label>
                                            <div className="col-sm-5 mb-1">
                                            <input type="text" value={month} onChange={(e)=>{setMonth(e.target.value)}} className="form-control" id="bedroom" />
                                            </div>
                                            <label htmlFor="year" className="col-sm-5 col-form-label">Year</label>
                                            <div className="col-sm-5 mb-1">
                                            <select className="form-select seasonSelect" aria-label="Default select example">
                                                <option value="0">2011</option>
                                                <option value="1">2012</option>
                                            </select>
                                            </div>
                                            <label htmlFor="time" className="col-sm-5 col-form-label">Time</label>
                                            <div className="col-sm-5 mb-1">
                                                <input type="text" value={time} onChange={(e)=>{setTime(e.target.value)}} className="form-control" id="time" />
                                            </div> 
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="season" className="col-sm-5 col-form-label">Season</label>
                                            <div className="col-sm-5">
                                            <select className="form-select seasonSelect" aria-label="Default select example">
                                                <option value="0">Spring</option>
                                                <option value="1">Summer</option>
                                                <option value="2">Fall</option>
                                                <option value="3">Winter</option>
                                            </select>
                                            </div>
                                        </div>
                                        <fieldset className="row mb-3">
                                            <label className="col-form-label col-sm-5 pt-0">Holiday</label>
                                            <div className="col-sm-10">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" />
                                                    <label className="form-check-label" htmlFor="gridRadios1">
                                                    Yes
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
                                                    <label className="form-check-label" htmlFor="gridRadios2">
                                                    No
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset className="row mb-3">
                                            <label className="col-form-label col-sm-5 pt-0">Working Day</label>
                                            <div className="col-sm-10">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="gridRadios2" id="gridRadios21" value="option1" />
                                                    <label className="form-check-label" htmlFor="gridRadios21">
                                                    Yes
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="gridRadios2" id="gridRadios22" value="option2" />
                                                    <label className="form-check-label" htmlFor="gridRadios22">
                                                    No
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className="row mb-3">
                                            <label htmlFor="weather" className="col-sm-5 col-form-label">Weather</label>
                                            <div className="col-sm-5">
                                            <select className="form-select seasonSelect" aria-label="Default select example">
                                                <option value="0">Clear</option>
                                                <option value="1">Mist</option>
                                                <option value="2">Light Snow/Light Rain</option>
                                                <option value="3">Heavy Rain/Thunderstorm/Snow+Fog</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="temp" className="col-sm-5 col-form-label">Temperature</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={temp} onChange={(e)=>{setTemp(e.target.value)}} className="form-control" id="size" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="humidity" className="col-sm-5 col-form-label">Humidity</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={humidity} onChange={(e)=>{setHumidity(e.target.value)}} className="form-control" id="bathroom" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="windspeed" className="col-sm-5 col-form-label">Wind Speed</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={windspeed} onChange={(e)=>{setWS(e.target.value)}} className="form-control" id="floors" />
                                            </div>
                                        </div>
                                        <center><button type="submit" className="btn btn-primary predictButton" onClick={bikePredictionButton}>Predict</button></center>
                                        <div className="row mb-3 returnVal">
                                            <label htmlFor="returnVal" className="col-sm-10 col-form-label">Predicted Value</label>
                                            <div className="col-sm-12">
                                            <input type="text" className="form-control" id="returnValBike" readOnly disabled placeholder='Click on predict to get predicted Value...' />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="btn-group docButton" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-primary docButton1" data-bs-toggle="modal" data-bs-target="#exampleModal">Documentation</button>
                                <button type="button" className="btn btn-primary docButton2">Visit Github Profile</button>
                            </div>
                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content wholeModel">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5 modelTitle" id="exampleModalLabel">Project Title</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body modelBody">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero dolores error facilis assumenda tempora reprehenderit sapiente, cum incidunt labore animi perspiciatis corrupti optio cupiditate commodi. Qui laudantium excepturi culpa. Vero illum ipsa architecto alias deserunt. Fugit aliquam eveniet vitae perferendis voluptate. Accusantium aliquam eum expedita a atque? Tenetur vel quidem cupiditate veritatis, ut, adipisci ratione temporibus delectus pariatur molestias voluptate quisquam repellat reiciendis architecto ipsum autem nisi blanditiis voluptatum aperiam asperiores reprehenderit. Sequi suscipit architecto magnam quisquam nobis, perspiciatis eius. Sapiente fugiat laborum itaque sed iusto incidunt illo dolore. Alias saepe magnam error, expedita, corrupti porro maiores aperiam harum quidem earum nulla accusantium delectus a voluptate voluptatem ea, officia adipisci. Cumque accusamus labore tempora cum itaque numquam autem sit optio. Soluta maxime vero aliquid, sint voluptates delectus veritatis cumque similique sunt earum corrupti necessitatibus debitis. Sapiente enim eos illo blanditiis asperiores magnam pariatur voluptate cupiditate id nesciunt est cum optio nobis, esse ipsa repellat magni in, culpa totam commodi aspernatur, fugiat omnis deserunt. Enim cum numquam voluptas aspernatur odit cupiditate corporis. A, facilis totam aperiam sint tempore molestiae ab nisi maxime labore quas alias quo quod itaque, fugit quae perspiciatis! Dolore obcaecati eaque magni dolores soluta optio impedit repudiandae. In?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary modelBut closeBut" data-bs-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-primary modelBut visitBut">Visit Github Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 colxx">
                    <div className="card cardxproj lregmodel2">
                        <div className="card-body cbodyx">
                            <div className="protitle p2title">Wine Quality Prediction</div>
                            <div className="prosubtitle p2subtitle">Predicting quality of wine using 12 different attributes</div>
                            <span className="badge text-bg-light">Machine Learning</span>
                            <span className="badge text-bg-light">Regression Model</span>
                            <center><button className="btn btn-primary inButton" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptionsWine" aria-controls="offcanvasWithBothOptions">Try out this project...</button></center>

                            <div className="offcanvas offcanvas-start text-bg-dark" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptionsWine" aria-labelledby="offcanvasWithBothOptionsLabel">
                                <div className="canvasHeader offcanvas-header">
                                    <h5 className="offcanvas-title canvasTitle" id="offcanvasWithBothOptionsLabel">Project Title</h5>
                                    <button type="button" className="btn-close btn-close-white outButton" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body canvasBody">
                                    <form className='formClass'>
                                        <fieldset className="row mb-3">
                                            <label className="col-form-label col-sm-5 pt-0">Type</label>
                                            <div className="col-sm-10">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gridRadiosWineType" id="gridRadiosWineType" value="option1" />
                                                <label className="form-check-label" htmlFor="gridRadiosWineType">
                                                    Red Wine
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gridRadiosWineType" id="gridRadiosWineType2" value="option2" />
                                                <label className="form-check-label" htmlFor="gridRadiosWineType2">
                                                    White Wine
                                                </label>
                                            </div>
                                            </div>
                                        </fieldset>
                                        <div className="row mb-3">
                                            <label htmlFor="fixedAcidity" className="col-sm-5 col-form-label">Fixed Acidity</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={fixedAcidity} onChange={(e)=>{setFA(e.target.value)}} className="form-control" id="fixedAcidity" required/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="volatileAcidity" className="col-sm-5 col-form-label">Volatile Acidity</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={volatileAcidity} onChange={(e)=>{setVA(e.target.value)}} className="form-control" id="volatileAcidity" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="citricAcid" className="col-sm-5 col-form-label">Citric Acid</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={citricAcid} onChange={(e)=>{setCA(e.target.value)}} className="form-control" id="citricAcid" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="residualSugar" className="col-sm-5 col-form-label">Residual Sugar</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={residualSugar} onChange={(e)=>{setRS(e.target.value)}} className="form-control" id="residualSugar" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="chlorides" className="col-sm-5 col-form-label">Chlorides</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={chlorides} onChange={(e)=>{setCH(e.target.value)}} className="form-control" id="chlorides" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="freeSulpher" className="col-sm-5 col-form-label">Free Sulpher Dioxide</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={freeSulpher} onChange={(e)=>{setFS(e.target.value)}} className="form-control" id="freeSulpher" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="totalSulpher" className="col-sm-5 col-form-label">Total Sulpher Dioxide</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={totalSulpher} onChange={(e)=>{setTS(e.target.value)}} className="form-control" id="totalSulpher" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="density" className="col-sm-5 col-form-label">Density</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={density} onChange={(e)=>{setDT(e.target.value)}} className="form-control" id="density" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="pH" className="col-sm-5 col-form-label">pH</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={pH} onChange={(e)=>{setPH(e.target.value)}} className="form-control" id="pH" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="sulphates" className="col-sm-5 col-form-label">Sulphates</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={sulphates} onChange={(e)=>{setSP(e.target.value)}} className="form-control" id="sulphates" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="alcohol" className="col-sm-5 col-form-label">Alcohol</label>
                                            <div className="col-sm-5">
                                            <input type="text" value={alcohol} onChange={(e)=>{setAH(e.target.value)}} className="form-control" id="alcohol" />
                                            </div>
                                        </div>
                                        <center><button type="submit" onClick={predictionButton} className="btn btn-primary predictButton">Predict</button></center>
                                        <div className="row mb-3 returnVal">
                                            <label htmlFor="returnVal" className="col-sm-10 col-form-label">Predicted Value</label>
                                            <div className="col-sm-12">
                                            <input type="text" className="form-control" id="returnValWine" readOnly disabled placeholder="Click on predict to predict the Value..." />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="btn-group docButton" role="group" aria-label="Basic example">
                                <button type="button" className="btn docButton1" data-bs-toggle="modal" data-bs-target="#wineModal">Documentation</button>
                                <button type="button" className="btn docButton2">Visit Github Profile</button>
                            </div>
                            <div className="modal fade" id="wineModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content wholeModel">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5 modelTitle" id="exampleModalLabel">Wine Quality Prediction</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body modelBody">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero dolores error facilis assumenda tempora reprehenderit sapiente, cum incidunt labore animi perspiciatis corrupti optio cupiditate commodi. Qui laudantium excepturi culpa. Vero illum ipsa architecto alias deserunt. Fugit aliquam eveniet vitae perferendis voluptate. Accusantium aliquam eum expedita a atque? Tenetur vel quidem cupiditate veritatis, ut, adipisci ratione temporibus delectus pariatur molestias voluptate quisquam repellat reiciendis architecto ipsum autem nisi blanditiis voluptatum aperiam asperiores reprehenderit. Sequi suscipit architecto magnam quisquam nobis, perspiciatis eius. Sapiente fugiat laborum itaque sed iusto incidunt illo dolore. Alias saepe magnam error, expedita, corrupti porro maiores aperiam harum quidem earum nulla accusantium delectus a voluptate voluptatem ea, officia adipisci. Cumque accusamus labore tempora cum itaque numquam autem sit optio. Soluta maxime vero aliquid, sint voluptates delectus veritatis cumque similique sunt earum corrupti necessitatibus debitis. Sapiente enim eos illo blanditiis asperiores magnam pariatur voluptate cupiditate id nesciunt est cum optio nobis, esse ipsa repellat magni in, culpa totam commodi aspernatur, fugiat omnis deserunt. Enim cum numquam voluptas aspernatur odit cupiditate corporis. A, facilis totam aperiam sint tempore molestiae ab nisi maxime labore quas alias quo quod itaque, fugit quae perspiciatis! Dolore obcaecati eaque magni dolores soluta optio impedit repudiandae. In?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary modelBut closeBut" data-bs-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-primary modelBut visitBut">Visit Github Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-sm-6 colxx">
                     <div className="card cardxproj salaryprediction">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">Data Science job Salary Prediction</div>
                            <div className="prosubtitle p1subtitle">With an eye on the growing demand in the data science job market, Model offers valuable insights into salary ranges to make informed decisions and empowers employers to establish competitive compensation packages.</div>
                            <span className="badge text-bg-light">Data Science</span>
                            <span className="badge text-bg-light">Data Analysis</span>
                            <span className="badge text-bg-light">Scikit-Learn</span>
                            <span className="badge text-bg-light">Pandas</span>
                            <span className="badge text-bg-light">Numpy</span>
                            <a href="https://github.com/aryan16x/job-salary-prediction" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 colxx">
                     <div className="card cardxproj powerbi">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">World Bank: Program Budget and All Funds PowerBI Report</div>
                            <div className="prosubtitle p1subtitle">Designed comprehensive Power BI report for the World Bank’s Program Budget and All Funds, enabling stakeholders to analyze and track budget allocations across 11 work programs and 63 units.</div>
                            <span className="badge text-bg-light">Data Analysis Report</span>
                            <span className="badge text-bg-light">PowerBI</span>
                            <span className="badge text-bg-light">Finance</span>
                            <a href="https://drive.google.com/file/d/1R3juoNCQDSVAuC5yBKm1kt6WFyebFFft/view?usp=sharing" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 colxx">
                     <div className="card cardxproj datacartel">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">Daracartel - The sole solution for Data Miners</div>
                            <div className="prosubtitle p1subtitle">Developed user-friendly website catering to ML practitioners, serving a substantial user base. It allows users to generate customized datasets with flexibility in feature selection.</div>
                            <span className="badge text-bg-light">Datasets</span>
                            <span className="badge text-bg-light">Web development</span>
                            <span className="badge text-bg-light">ReactJS</span>
                            <span className="badge text-bg-light">Flask</span>
                            <span className="badge text-bg-light">RestAPI</span>
                            <span className="badge text-bg-light">OpenAI's API</span>
                            <span className="badge text-bg-light">MaterialUI</span>
                            <a href="https://github.com/aryan16x/DataCartel" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 colxx">
                     <div className="card cardxproj hareestar">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">Hareestar Infrastructure</div>
                            <div className="prosubtitle p1subtitle">By employing modern web technologies, successfully created a professional websit,  user-friendly interface that meets high standards, resulting in an 80% increase in business inquiries.</div>
                            <span className="badge text-bg-light">Web development</span>
                            <span className="badge text-bg-light">ReactJS</span>
                            <span className="badge text-bg-light">Flask</span>
                            <span className="badge text-bg-light">RestAPI</span>
                            <span className="badge text-bg-light">MaterialUI</span>
                            <a href="https://hareestarinfra.in" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 colxx">
                     <div className="card cardxproj amazonclone">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">Amazon-Clone</div>
                            <div className="prosubtitle p1subtitle"> Designed and Created the Amazon-Clone using ReactJS and Firebase. Added multiple features like add to cart, billing, and sign-in. Hosted the website on firebase</div>
                            <span className="badge text-bg-light">Web development</span>
                            <span className="badge text-bg-light">ReactJS</span>
                            <span className="badge text-bg-light">Firebase</span>
                            <a href="https://clone-ece5b.web.app" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 colxx">
                     <div className="card cardxproj youtubeclone">
                        <div className="card-body cbodyx">
                            <div className="protitle p1title">YouTube-Clone</div>
                            <div className="prosubtitle p1subtitle">Replicate the YouTube using ReactJS and deployed using Netlify. Learned multiple concepts like contextAPI, pagination, and react Router</div>
                            <span className="badge text-bg-light">Web development</span>
                            <span className="badge text-bg-light">ReactJS</span>
                            <span className="badge text-bg-light">MaterialUI</span>
                            <span className="badge text-bg-light">Netlify</span>
                            <span className="badge text-bg-light">RestAPI</span>
                            <a href="https://youtubearyan16x.netlify.app" rel='noreferrer' target="_blank"><div className="probutton">
                                <center><button className="btn btn-primary inButton" type="button"><p></p></button></center>
                            </div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Projects