import React from "react";
import { useState } from "react";
import axios from 'axios';
import './blueberry.css'

function Blueberry() {
  const [clonesize, setclonesize] = useState(25);
  const [honeybee, sethoneybee] = useState(0.5);
  const [bumbles, setbumbles] = useState(0.25);
  const [andrena, setandrena] = useState(0.63);
  const [osmia, setosmia] = useState(0.63);
  const [MaxOfUpperTRange, setMaxOfUpperTRange] = useState(77.4);
  const [MinOfUpperTRange, setMinOfUpperTRange] = useState(46.8);
  const [AverageOfUpperTRange, setAverageOfUpperTRange] = useState(64.7);
  const [MaxOfLowerTRange, setMaxOfLowerTRange] = useState(55.8);
  const [MinOfLowerTRange, setMinOfLowerTRange] = useState(27.0);
  const [AverageOfLowerTRange, setAverageOfLowerTRange] = useState(45.8);
  const [RainingDays, setRainingDays] = useState(24.0);
  const [AverageRainingDays, setAverageRainingDays] = useState(0.39);
  const [fruitset, setfruitset] = useState(0.579677);
  const [fruitmass, setfruitmass] = useState(0.494165);
  const [seeds, setseeds] = useState(40.484512);

  const predictionButton = (e) => {
    e.preventDefault();
    axios
        .post("http://aryan16x2.pythonanywhere.com/blueberry", {
            "clonesize" :clonesize,
            "honeybee" :honeybee,
            "bumbles" : bumbles,
            "andrena" : andrena,
            "osmia" : osmia,
            "MaxOfUpperTRange" : MaxOfUpperTRange,
            "MinOfUpperTRange" : MinOfUpperTRange,
            "AverageOfUpperTRange" : AverageOfUpperTRange, 
            "MaxOfLowerTRange" : MaxOfLowerTRange, 
            "MinOfLowerTRange" : MinOfLowerTRange,
            "AverageOfLowerTRange" : AverageOfLowerTRange, 
            "RainingDays" : RainingDays,
            "AverageRainingDays" : AverageRainingDays,
            "fruitset" : fruitset,
            "fruitmass" : fruitmass, 
            "seeds" : seeds
        })
        .then((res) => {
            document.getElementById("returnPredictedValue").placeholder = res.data;
        })
        .catch((err) => {
            console.error(err);
        })
  }

  return (
    <>
      <div className="col-sm-6 colxx">
        <div className="card cardxproj lregmodel">
          <div className="card-body cbodyx">
            <div className="protitle p1title">Blue Berry Yield Prediction</div>
            <div className="prosubtitle p1subtitle">
              End-to-end ML model which is used to predict blueberry yield using 15 different features. Setup user-end pipeline for prediction. Try out by clicking on this card
            </div>
            <span className="badge text-bg-light">Machine Learning</span>
            <span className="badge text-bg-light">Regression Model</span>
            <span className="badge text-bg-light">Flask</span>
            <span className="badge text-bg-light">Scikit-Learn</span>
            <center>
              <div className="probutton">
                <button
                  className="btn btn-primary inButton inButtonBlueberry"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions"
                  aria-controls="offcanvasWithBothOptions"
                >
                  <p></p>
                </button>
              </div>
            </center>

            <div
              className="offcanvas offcanvas-start text-bg-dark"
              data-bs-scroll="true"
              tabIndex="-1"
              id="offcanvasWithBothOptions"
              aria-labelledby="offcanvasWithBothOptionsLabel"
            >
              <div className="canvasHeader offcanvas-header">
                <h5
                  className="offcanvas-title canvasTitle"
                  id="offcanvasWithBothOptionsLabel"
                >
                  Blueberry Yield Prediction
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white outButton"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body canvasBody">
                <form className="formClass">
                  <div className="row mb-3">
                    <div class="mb-3">
                      <label class="form-label">clonesize</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="clonesize"
                        value={clonesize}
                        onChange={(e) => {setclonesize(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">honeybee</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="honeybee"
                        value={honeybee}
                        onChange={(e) => {sethoneybee(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">bumbles</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="bumbles"
                        value={bumbles}
                        onChange={(e) => {setbumbles(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">andrena</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="andrena"
                        value={andrena}
                        onChange={(e) => {setandrena(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">osmia</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="osmia"
                        value={osmia}
                        onChange={(e) => {setosmia(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">MaxOfUpperTRange</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="MaxOfUpperTRange"
                        value={MaxOfUpperTRange}
                        onChange={(e) => {setMaxOfUpperTRange(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">MinOfUpperTRange</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="MinOfUpperTRange"
                        value={MinOfUpperTRange}
                        onChange={(e) => {setMinOfUpperTRange(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">AverageOfUpperTRange</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="AverageOfUpperTRange"
                        value={AverageOfUpperTRange}
                        onChange={(e) => {setAverageOfUpperTRange(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">MaxOfLowerTRange</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="MaxOfLowerTRange"
                        value={MaxOfLowerTRange}
                        onChange={(e) => {setMaxOfLowerTRange(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">MinOfLowerTRange</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="MinOfLowerTRange"
                        value={MinOfLowerTRange}
                        onChange={(e) => {setMinOfLowerTRange(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">AverageOfLowerTRange</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="AverageOfLowerTRange"
                        value={AverageOfLowerTRange}
                        onChange={(e) => {setAverageOfLowerTRange(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">RainingDays</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="RainingDays"
                        value={RainingDays}
                        onChange={(e) => {setRainingDays(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">AverageRainingDays</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="AverageRainingDays"
                        value={AverageRainingDays}
                        onChange={(e) => {setAverageRainingDays(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">fruitset</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="fruitset"
                        value={fruitset}
                        onChange={(e) => {setfruitset(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">fruitmass</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="fruitmass"
                        value={fruitmass}
                        onChange={(e) => {setfruitmass(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">seeds</label>
                      <input
                        class="form-control"
                        type="number"
                        step="any"
                        name="seeds"
                        value={seeds}
                        onChange={(e) => {setseeds(e.target.value)}}
                      />
                    </div>
                    <div class="mb-3">
                    <center><button type="submit" onClick={predictionButton} className="btn btn-primary predictButton">Predict</button></center>
                        <div className="row mb-3 returnVal">
                            <label htmlFor="returnVal" className="col-sm-10 col-form-label">Predicted Value</label>
                            <div className="col-sm-12">
                                <input type="text" className="form-control" id="returnPredictedValue" readOnly disabled placeholder="Click on predict to predict the Value..." />
                            </div>
                        </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <div
              className="btn-group docButton"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className="btn btn-primary docButton1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Documentation
              </button>
              <button type="button" className="btn btn-primary docButton2">
                Visit Github Profile
              </button>
            </div> */}
            {/* <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content wholeModel">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5 modelTitle"
                      id="exampleModalLabel"
                    >
                      Project Title
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body modelBody">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Libero dolores error facilis assumenda tempora reprehenderit
                    sapiente, cum incidunt labore animi perspiciatis corrupti
                    optio cupiditate commodi. Qui laudantium excepturi culpa.
                    Vero illum ipsa architecto alias deserunt. Fugit aliquam
                    eveniet vitae perferendis voluptate. Accusantium aliquam eum
                    expedita a atque? Tenetur vel quidem cupiditate veritatis,
                    ut, adipisci ratione temporibus delectus pariatur molestias
                    voluptate quisquam repellat reiciendis architecto ipsum
                    autem nisi blanditiis voluptatum aperiam asperiores
                    reprehenderit. Sequi suscipit architecto magnam quisquam
                    nobis, perspiciatis eius. Sapiente fugiat laborum itaque sed
                    iusto incidunt illo dolore. Alias saepe magnam error,
                    expedita, corrupti porro maiores aperiam harum quidem earum
                    nulla accusantium delectus a voluptate voluptatem ea,
                    officia adipisci. Cumque accusamus labore tempora cum itaque
                    numquam autem sit optio. Soluta maxime vero aliquid, sint
                    voluptates delectus veritatis cumque similique sunt earum
                    corrupti necessitatibus debitis. Sapiente enim eos illo
                    blanditiis asperiores magnam pariatur voluptate cupiditate
                    id nesciunt est cum optio nobis, esse ipsa repellat magni
                    in, culpa totam commodi aspernatur, fugiat omnis deserunt.
                    Enim cum numquam voluptas aspernatur odit cupiditate
                    corporis. A, facilis totam aperiam sint tempore molestiae ab
                    nisi maxime labore quas alias quo quod itaque, fugit quae
                    perspiciatis! Dolore obcaecati eaque magni dolores soluta
                    optio impedit repudiandae. In?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary modelBut closeBut"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary modelBut visitBut"
                    >
                      Visit Github Profile
                    </button>
                  </div> */}
                {/* </div> */}
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blueberry;
